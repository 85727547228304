import React from 'react';
import KwPanel from '../../common/KwPanel';
import { Link } from 'react-router-dom';
import FormStatic from '../../common/FormStatic';
import {
  CommaNumber_NoDecimal,
  Date_AsString_NoHtml,
} from '../../../util/formatter';
import Loading from '../../common/Loading';
import TransactionStatusBadge from './TransactionStatusBadge';
import UserLink from '../../common/links/UserLink';
import GameLink from '../../common/links/GameLink';
import CompanyLink from '../../common/links/CompanyLink';
import { useTranslation } from 'react-i18next';

const IngestedTransactionDetails = ({ transaction, currentUser }) => {
  const { t } = useTranslation();
  return transaction ? (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin ">{t('Ingestion details')}</h2>
      </header>
      <div className="row">
        <div className="col">
          {transaction.requestGroupId ? (
            <FormStatic label={t('Request')}>
              <Link to={'/request/' + transaction.requestGroupId}>
                {transaction.requestReference}
              </Link>
            </FormStatic>
          ) : (
            <FormStatic label={t('Key pool')}>
              <Link to={'/workflow/' + transaction.keyWorkflowId}>
                {transaction.keyWorkflowName}
              </Link>
            </FormStatic>
          )}
          <FormStatic label={t('Game')}>
            <GameLink
              gameId={transaction.gameId}
              skuId={transaction.gameSkuId}
              gameTitle={transaction.gameName}
              skuName={transaction.gameSkuName}
              currentUser={currentUser}
              className="game-title"
            ></GameLink>
          </FormStatic>
          <FormStatic label={t('Total keys')} className="num no-wrap">
            {CommaNumber_NoDecimal(transaction.keyCount)}
          </FormStatic>
          <FormStatic label={t('Uploaded by')}>
            <div className="d-flex flex-column">
              <UserLink
                id={transaction.manualImportUserId}
                name={transaction.manualImportUser}
                currentUser={currentUser}
              />
            </div>
          </FormStatic>
          <FormStatic label={t('Uploaded by company')}>
            <div className="d-flex flex-column">
              <CompanyLink
                id={transaction.manualImportUserCompanyId}
                name={transaction.manualImportUserCompany}
              />
            </div>
          </FormStatic>
        </div>
        <div className="col">
          <FormStatic label={t('Date uploaded')}>
            {Date_AsString_NoHtml(transaction.uploadedDate)}
          </FormStatic>
          <FormStatic label={t('Status')}>
            <TransactionStatusBadge item={transaction}></TransactionStatusBadge>
          </FormStatic>
        </div>
      </div>
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
};

export default IngestedTransactionDetails;
