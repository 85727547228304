import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getDisabledTransactionById,
  downloadDisabledKeys,
  clearDisabledTransaction,
  authoriseDisabledTransaction,
} from '../../actions/keyCodeActions';
import ReportedDisabledTransactionDetails from './components/ReportedDisabledTransactionDetails';
import ReportedDisabledTransactionSkusList from './components/ReportedDisabledTransactionSkusList';
import KeyProcessProgressBar from './components/KeyProcessProgressBar';
import ActionBar from '../layout/ActionBar';
import UploadDisabled from './slideout/UploadDisabled';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import KwPanel from '../common/KwPanel';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import DisableOnSteam from './slideout/DisableOnSteam';
import DisableKeyProviderResults from './components/DisableKeyProviderResults';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';

function ReportedDisabledTransaction({
  id,
  history,
  location,
  addToGroup,
  removeFromGroup,
  disabledTransactions,
  clearDisabledTransaction,
  currentUser,
}) {
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState();
  const [reload, setReload] = useState();
  const [uploadSlideoutOpen, setUploadSlideoutOpen] = useState(false);
  const [disableOnSteamSlideoutOpen, setDisableOnSteamSlideoutOpen] =
    useState(false);
  const [isWaitingForSteam, setIsWaitingForSteam] = useState(false);

  useEffect(() => {
    if (id) {
      // get transaction
      getDisabledTransactionById(id)
        .then((d) => {
          setTransaction(d);
          console.log(d);
        })
        .catch((e) => {
          console.error(e);
          history.push('/keys/reported-disabled/');
          toast.error(t('Transaction not found'));
        });
    }
  }, [id, history, reload, t]);

  useEffect(() => {
    if (
      transaction &&
      (transaction.status === 'Pending' || isWaitingForSteam)
    ) {
      const key = `disabled:${transaction.id}`;
      addToGroup(key);
      return () => removeFromGroup(key);
    }
  }, [transaction, addToGroup, removeFromGroup, isWaitingForSteam]);

  useEffect(() => {
    if (disabledTransactions && disabledTransactions.length > 0) {
      const disabledTransaction = disabledTransactions.find((x) => x.id === id);
      if (disabledTransaction) {
        if (disabledTransaction.status === 'reload') {
          setReload(new Date());
        } else {
          setTransaction((prevState) => ({
            ...prevState,
            ...disabledTransaction,
          }));
        }
      }
      return () => clearDisabledTransaction(id);
    }
  }, [disabledTransactions, id, clearDisabledTransaction]);

  const handleDownloadKeys = (e) => {
    e.cancelBubble = true;
    downloadDisabledKeys(id).catch((e) => {
      toast.error(e.message || t('Failed to download keys'));
    });
  };

  const handleDownloadItem = (e, item) => {
    e.cancelBubble = true;
    downloadDisabledKeys(id, item.skuId, item.companyId).catch(() => {
      toast.error(t('Failed to download keys'));
    });
  };

  const handleAuthorise = (e) => {
    e.cancelBubble = true;
    authoriseDisabledTransaction(id)
      .then((authorised) => setTransaction({ ...transaction, ...authorised }))
      .catch((e) => {
        toast.error(e.message || t('Failed to authorise transaction'));
      });
  };

  return (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: '/keys/reported-disabled',
            label: t('Disabled transactions'),
          },
          { label: t('Disabled transaction') },
        ]}
      >
        <Button
          className="btn btn-default me-2"
          onClick={() => setDisableOnSteamSlideoutOpen(true)}
          isDisabled={
            !transaction ||
            (!transaction.success && !transaction.partialSuccess)
          }
          text={t('Disable on Steam')}
        />
        <Button
          className="btn btn-default me-2"
          onClick={handleDownloadKeys}
          isDisabled={
            !transaction ||
            (!transaction.success && !transaction.partialSuccess)
          }
          text={t('Download')}
        />
        <Button
          className="btn btn-primary"
          onClick={() => {
            setUploadSlideoutOpen(true);
          }}
          text={t('Disable more keys')}
        />
      </ActionBar>
      {transaction && (
        <>
          <ReportedDisabledTransactionDetails
            transaction={transaction}
            currentUser={currentUser}
          />
          {transaction.success && (
            <ReportedDisabledTransactionSkusList
              id={id}
              location={location}
              reload={reload}
              handleDownload={handleDownloadItem}
            />
          )}
          {!transaction.isAuthorised ? (
            <KwPanel>
              <header className="mb-2">
                <h2>{t('Authorise transaction')}</h2>
              </header>
              <Button
                className="btn btn-primary"
                onClick={handleAuthorise}
                text={t('Authorise')}
              />
            </KwPanel>
          ) : (
            <KeyProcessProgressBar transaction={transaction} />
          )}
          {transaction.results && transaction.results.length > 0 && (
            <DisableKeyProviderResults
              results={transaction.results}
            ></DisableKeyProviderResults>
          )}
        </>
      )}

      <SlidingPane
        isOpen={uploadSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setUploadSlideoutOpen(false)}
      >
        <UploadDisabled
          done={(d) => {
            setUploadSlideoutOpen(false);
            setReload(new Date());
          }}
          cancel={() => setUploadSlideoutOpen(false)}
        />
      </SlidingPane>

      <SlidingPane
        isOpen={disableOnSteamSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDisableOnSteamSlideoutOpen(false)}
      >
        <DisableOnSteam
          id={id}
          done={(d) => {
            setDisableOnSteamSlideoutOpen(false);
            setIsWaitingForSteam(true);
          }}
          cancel={() => setDisableOnSteamSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    disabledTransactions: state.disabledTransactions,
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
  clearDisabledTransaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportedDisabledTransaction);
