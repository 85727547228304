import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import SteamBulkRequest from './SteamBulkRequest';
import ReassignBulk from './ReassignBulk';
import ChangeStatusBulk from './ChangeStatusBulk';
import SlidingPane from 'react-sliding-pane';
import icons from '../../../constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function BulkActions({ done, cancel, items }) {
  const { t } = useTranslation();
  const [bulkStatusChangeSlideoutOpen, setBulkStatusChangeSlideoutOpen] =
    useState(false);
  const [
    bulkStatusChangeSlideoutRequests,
    setBulkStatusChangeSlideoutRequests,
  ] = useState();

  const [bulkReassignSlideoutOpen, setBulkReassignSlideoutOpen] =
    useState(false);
  const [
    bulkReassignSlideoutRequestGroups,
    setBulkReassignSlideoutRequestGroups,
  ] = useState();

  const [bulkSteamRequestSlideoutOpen, setBulkSteamRequestSlideoutOpen] =
    useState(false);
  const [bulkSteamRequestSlideoutSKUs, setBulkSteamRequestSlideoutSKUs] =
    useState();

  const BulkActionItem = ({ title, description, icon, onClick }) => (
    <div
      className="col-sm-12 template p-0 bulk action-update"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <div className="template-detail p-4">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="icon-wrapper">
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
  );

  const handleBulkStatusChange = () => {
    let requests = [];
    for (const i in items) {
      const row = items[i];
      if (row.games) {
        for (const j in row.games) {
          const requestRow = row.games[j];
          requests.push(requestRow.requestId);
        }
      } else {
        requests.push(row.id);
      }
    }
    setBulkStatusChangeSlideoutRequests(requests);
    setBulkStatusChangeSlideoutOpen(true);
  };

  const handleBulkReassign = () => {
    const requestGroups = [
      ...new Set(items.map((x) => x.requestGroupId ?? x.id)),
    ];
    setBulkReassignSlideoutRequestGroups(requestGroups);
    setBulkReassignSlideoutOpen(true);
  };
  const handleBulkSteamRequest = () => {
    let skus = [];
    for (const i in items) {
      const row = items[i];
      if (row.games) {
        for (const j in row.games) {
          const requestRow = row.games[j];
          if (requestRow.canOrderKeys) {
            skus = addSteamRequestItem(requestRow, requestRow.requestId, skus);
          }
        }
      } else if (row.keyProviderReference && row.canOrderKeys) {
        skus = addSteamRequestItem(row, row.id, skus);
      }
    }
    if (skus.length > 0) {
      setBulkSteamRequestSlideoutSKUs(skus);
      setBulkSteamRequestSlideoutOpen(true);
    } else {
      toast.error(
        t(
          'None of the selected Key Requests are in the correct state or they do not support key pools'
        )
      );
    }
  };

  const addSteamRequestItem = (row, keyRequestId, skus) => {
    const keyPoolRow = {
      keyWorkflowId: row.keyWorkflowId,
      keyWorkflowName: row.keyWorkflowName,
      keyProviderReference: row.keyProviderReference,
      numberOfKeys: row.numberOfKeys,
    };
    skus.push({
      id: row.skuId,
      gameName: row.gameTitle,
      name: row.skuName,
      keyRequestId: keyRequestId,
      packageTypeId: row.packageTypeId,
      keyPools: [keyPoolRow],
    });
    return skus;
  };

  return (
    <>
      <SlideoutLayout title={t('Bulk actions')} cancel={cancel} done={done}>
        <BulkActionItem
          title={t('Change status')}
          description={t(
            'Change the status of multiple requests with pending keys'
          )}
          icon={`${icons.CHANGE_STATUS} c-blue`}
          onClick={handleBulkStatusChange}
        ></BulkActionItem>
        <BulkActionItem
          title={t('Reassign')}
          description={t('Reassign the request to a user')}
          icon={`${icons.REASSIGN} c-red`}
          onClick={handleBulkReassign}
        ></BulkActionItem>
        <BulkActionItem
          title={t('Request on Steam')}
          description={t('Send an automated request to Steam')}
          icon={`${icons.REQUEST_ON_STEAM}`}
          onClick={handleBulkSteamRequest}
        ></BulkActionItem>
      </SlideoutLayout>

      <SlidingPane
        isOpen={bulkStatusChangeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setBulkStatusChangeSlideoutOpen(false)}
      >
        <ChangeStatusBulk
          done={() => {
            setBulkStatusChangeSlideoutOpen(false);
            done();
          }}
          cancel={() => setBulkStatusChangeSlideoutOpen(false)}
          requests={bulkStatusChangeSlideoutRequests}
        ></ChangeStatusBulk>
      </SlidingPane>

      <SlidingPane
        isOpen={bulkReassignSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setBulkReassignSlideoutOpen(false)}
      >
        <ReassignBulk
          done={() => {
            setBulkReassignSlideoutOpen(false);
            done();
          }}
          cancel={() => setBulkReassignSlideoutOpen(false)}
          requestGroups={bulkReassignSlideoutRequestGroups}
        ></ReassignBulk>
      </SlidingPane>

      <SlidingPane
        isOpen={bulkSteamRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setBulkSteamRequestSlideoutOpen(false)}
      >
        <SteamBulkRequest
          done={() => {
            setBulkSteamRequestSlideoutOpen(false);
          }}
          cancel={() => setBulkSteamRequestSlideoutOpen(false)}
          skus={bulkSteamRequestSlideoutSKUs}
        ></SteamBulkRequest>
      </SlidingPane>
    </>
  );
}
BulkActions.propTypes = {
  cancel: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default BulkActions;
