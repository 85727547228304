import React, { useState } from 'react';
import {
  loadDisabledTransactions,
  exportDisabledTransactionData,
} from '../../../actions/keyCodeActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal, Date_AsString } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import useUploadNotificationsGeneric from '../../../hooks/useUploadNotificationsGeneric';
import TransactionStatusBadge from './TransactionStatusBadge';
import KeyProviderLink from '../../common/links/KeyProviderLink';
import UserLink from '../../common/links/UserLink';
import { useTranslation } from 'react-i18next';

function ReportedDisabledList({
  location,
  reload,
  addToGroup,
  removeFromGroup,
  disabledTransactions,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'creationDate',
    'batchCount',
    'keyProviderName',
    'manualImportUser',
    'reasons',
    'reason',
    'description',
    'status',
  ];
  const defaultVisibleColumns = allColumns;
  const [transactions, setTransactions] = useState();

  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'reporteddisabledlist-settings',
      getTableSettingsDefaults({
        defaultSort: 'creationDate',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      loadDisabledTransactions,
      setTransactions,
      reload,
      t('Failed to load disabled transactions'),
      location
    );
  useUploadNotificationsGeneric(
    transactions,
    setTransactions,
    'disabled',
    addToGroup,
    removeFromGroup,
    disabledTransactions,
    'id',
    (row) => row.status === 'Pending'
  );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'creationDate',
      Label: t('Requested date'),
      width: '10%',
      Render: (item) => (
        <Link to={'/keys/reported-disabled/' + item.id}>
          {Date_AsString(item.creationDate)}
        </Link>
      ),
    },
    {
      Key: 'batchCount',
      Label: t('Key count'),
      className: 'text-right num no-wrap',
      width: '5%',
      Render: (item) =>
        item.batchCount >= 0 ? CommaNumber_NoDecimal(item.batchCount) : '--',
    },
    {
      Key: 'keyProviderName',
      Label: t('Provider'),
      width: '10%',
      className: '',
      Render: (item) => (
        <KeyProviderLink id={item.keyProviderId} name={item.keyProviderName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'manualImportUser',
      Label: t('Requested by'),
      width: '10%',
      className: '',
      Render: (item) => (
        <>
          <UserLink id={item.manualImportUserId} name={item.manualImportUser} />
          <br />
          {item.manualImportUserCompany}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'status',
      Label: t('Status'),
      width: '5%',
      className: '',
      Render: (item) => (
        <TransactionStatusBadge item={item}></TransactionStatusBadge>
      ),
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('All')}</option>
            <option value="failed">{t('Failed')}</option>
            <option value="partial success">{t('Partial success')}</option>
            <option value="pending authorisation">
              {t('Pending authorisation')}
            </option>
            <option value="pending">{t('Pending')}</option>
            <option value="success">{t('Success')}</option>
          </select>
        ),
      },
    },
    {
      Key: 'reasons',
      Label: t('Reason'),
      width: '10%',
      Render: (item) =>
        item.reasons.map((x) => (
          <span key={x} className="badge bg-pink me-2">
            {x}
          </span>
        )),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'reason',
      Label: t('More info'),
      width: '15%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'description',
      Label: t('Description'),
      width: '15%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return transactions ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={transactions.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={transactions.totalRowCount}
        filteredRowCount={transactions.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportDisabledTransactionData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default ReportedDisabledList;
