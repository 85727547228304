import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import TableColumnSlideout from './slideout/TableColumnSlideout';
import icons from '../../constants/icons';
import TableScopes from './TableScopes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KebabMenu from './KebabMenu';
import Button from './Button';
import { useTranslation } from 'react-i18next';
function TableActionBar({
  exportData,
  children,
  setBulkActionsSlideoutOpen,
  itemsChecked,
  bulkActionTitle,
  columns,
  allColumns,
  defaultVisibleColumns,
  setVisibleColumns,
  scopes,
  location,
  hideScopeTags,
  filters,
}) {
  const { t } = useTranslation();
  const [editColumnsSlideoutOpen, setEditColumnsSlideoutOpen] = useState(false);

  const handleExportData = (filtered) => {
    const resolveValue = (value) =>
      value === '00000000-0000-0000-0000-000000000000' ? true : value;

    const scopesAsObject = scopes
      ? [...scopes].reduce(
          (acc, { id, scope }) =>
            Object.assign(acc, { [scope]: resolveValue(id) }),
          {}
        )
      : null;
    console.log({ scopes, scopesAsObject });
    exportData(filtered ? filters : null, scopesAsObject).catch(() => {
      toast.error(t('Failed to download data'));
    });
  };
  return (
    <>
      <div className="toolbar d-flex justify-content-between">
        <div className="d-flex">
          {children}
          <div className="d-none d-md-block">
            {children && !hideScopeTags && scopes && scopes.length > 0 && (
              <span className="pipe border-start ms-2 me-2"></span>
            )}
            {!hideScopeTags && scopes && scopes.length > 0 && (
              <TableScopes scopes={scopes} location={location}></TableScopes>
            )}
          </div>
        </div>
        <div className="d-flex">
          {exportData && (
            <KebabMenu
              className="inline-icons"
              buttonClassName="btn-outline"
              buttonIcon={icons.DOWNLOAD_DATA}
              doNotFilterButtons={true}
              buttons={[
                <Button
                  key="exportFilteredData"
                  onClick={() => handleExportData(true)}
                  icon={icons.DOWNLOAD_FILTERED_DATA}
                  text={t('Export filtered data')}
                  title={t('Export filtered data')}
                ></Button>,
                <Button
                  key="exportAllData"
                  onClick={() => handleExportData(false)}
                  icon={icons.DOWNLOAD_ALL_DATA}
                  text={t('Export all data')}
                  title={t('Export all data')}
                ></Button>,
              ]}
            ></KebabMenu>
          )}
          {allColumns && (
            <button
              className="btn btn-default btn-outline btn--square ms-2"
              onClick={() => setEditColumnsSlideoutOpen(true)}
              title={t('Column options')}
            >
              <FontAwesomeIcon
                icon={icons.TABLE_SETTINGS}
                fixedWidth
                aria-hidden="true"
              ></FontAwesomeIcon>
            </button>
          )}
          {setBulkActionsSlideoutOpen && (
            <>
              <span className="pipe border-start ms-2"></span>

              <button
                className="btn btn-default btn--square ms-2 btn-context-menu"
                onClick={() => setBulkActionsSlideoutOpen(true)}
                disabled={itemsChecked.length === 0}
                title={bulkActionTitle}
                style={{
                  position: 'relative',
                }}
              >
                <FontAwesomeIcon
                  icon={icons.TABLE_BULK}
                  fixedWidth
                  aria-hidden="true"
                ></FontAwesomeIcon>
                {itemsChecked.length > 0 && (
                  <span className="bulk-selection-number">
                    {itemsChecked.length < 100 && itemsChecked.length}
                  </span>
                )}
              </button>
            </>
          )}
        </div>
      </div>
      <div className="scopebar pt-0 d-block d-md-none">
        <div className="scopebar-inner">
          {scopes && scopes.length > 0 && (
            <TableScopes scopes={scopes} location={location}></TableScopes>
          )}
        </div>
      </div>
      <SlidingPane
        isOpen={editColumnsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setEditColumnsSlideoutOpen(false)}
      >
        <TableColumnSlideout
          done={(cols) => {
            setVisibleColumns(cols);
            setEditColumnsSlideoutOpen(false);
          }}
          cancel={() => setEditColumnsSlideoutOpen(false)}
          visibleColumns={columns}
          allColumns={allColumns}
          defaultVisibleColumns={defaultVisibleColumns}
        />
      </SlidingPane>
    </>
  );
}

export default TableActionBar;
