import React, { useState } from 'react';
import {
  exportWorkflowsByRequesters,
  loadWorkflowsByRequesters,
} from '../../../actions/reportActions';
import Gravatar from 'react-gravatar';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import WorkflowLink from '../../common/links/WorkflowLink';
import UserLink from '../../common/links/UserLink';
import { NavLink } from 'react-router-dom';
import CompanyLink from '../../common/links/CompanyLink';
import { useTranslation, Trans } from 'react-i18next';

function WorkflowByRequestersList({ location, currentUser }) {
  const { t } = useTranslation();
  const allColumns = ['keyWorkflowName', 'fullName', 'companyName', 'how'];
  const defaultVisibleColumns = allColumns;
  const [reload] = useState();
  const [items, setItems] = useState();
  const params = new URLSearchParams(location.search);

  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'workflowsbyrequestersreport-settings',
      getTableSettingsDefaults({
        defaultSort: 'keyWorkflowName',
        defaultVisibleColumns,
      }),
      null,
      loadWorkflowsByRequesters,
      setItems,
      reload,
      t('Failed to load report'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'keyWorkflowName',
      Label: t('Workflow name'),
      Render: (item) => (
        <WorkflowLink
          id={item.keyWorkflowId}
          name={item.keyWorkflowName}
          colour={item.keyWorkflowColourHex}
        ></WorkflowLink>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'fullName',
      Label: t('Name'),
      width: '30%',
      Render: (item) => (
        <div style={{ display: 'flex' }}>
          <Gravatar
            email={item?.emailAddress}
            size={20}
            rating="pg"
            default="retro"
            className="img-circle me-3"
          />
          <UserLink
            id={item.userProfileId}
            name={item.fullName}
            currentUser={currentUser.id}
            imageSize={32}
          />
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'companyName',
      Label: t('Company'),
      Render: (row) => (
        <div className="p-2 d-flex align-items-center company-td">
          <CompanyLink
            id={row.companyId}
            name={row.companyName}
            imageId={row.imageId}
            imageSize={32}
            colourHex={row.keyWorkflowColourHex}
          />
        </div>
      ),
    },
    {
      Key: 'how',
      Label: t('How'),
      Render: (row) => renderBasedOnHow(row, t),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return items ? (
    <KwPanel className="mb-4">
      <Table
        className="table-v-stripes"
        columns={columns}
        allColumns={allColumns}
        scopes={items.scopes}
        rows={items.data.map((x, index) => {
          return {
            Key: `${x.keyWorkflowId}-${x.userProfileGroupId}-${x.userProfileId}`,
            ...x,
          };
        })}
        totalRowCount={items.totalRowCount}
        filteredRowCount={items.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportWorkflowsByRequesters}
        location={location}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

const renderBasedOnHow = (row, t) => {
  switch (row.how.toLowerCase()) {
    case 'member of group':
      return (
        <div>
          <Trans
            i18nKey="memberOfGroup"
            components={{
              NavLink: (
                <NavLink
                  to={`/settings/user-profile-group/${row.userProfileGroupId}`}
                />
              ),
            }}
          >
            Member of <NavLink>{{ groupName: row.groupName }}</NavLink>
          </Trans>
        </div>
      );
    case 'member of company':
      return (
        <div>
          <Trans
            i18nKey="memberOfCompany"
            components={{
              NavLink: <NavLink to={`/company/${row.companyId}`} />,
            }}
          >
            Member of <NavLink>{{ companyName: row.companyName }}</NavLink>
          </Trans>
        </div>
      );
    case 'direct requester':
      return <div>{t('Direct requester')}</div>;
    default:
      return <div>{t('Unknown member')}</div>;
  }
};

export default WorkflowByRequestersList;
