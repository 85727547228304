import React from 'react';
import KwPanel from '../../common/KwPanel';
import { NavLink } from 'react-router-dom';
import FormStatic from '../../common/FormStatic';
import {
  CommaNumber_NoDecimal,
  Date_AsString_NoHtml,
} from '../../../util/formatter';
import TransactionStatusBadge from './TransactionStatusBadge';
import GameLink from '../../common/links/GameLink';
import UserLink from '../../common/links/UserLink';
import { useTranslation } from 'react-i18next';

const MovedTransactionDetails = ({ transaction, currentUser }) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <header className="mb-2">
        <h2 className="no-margin ">{t('Moved transaction details')}</h2>
      </header>
      <div className="row">
        <div className="col">
          <FormStatic label={t('SKU')}>
            <GameLink
              gameId={transaction.gameId}
              skuId={transaction.skuId}
              gameTitle={transaction.gameTitle}
              skuName={transaction.skuName}
              key={transaction.moveTransactionId}
              currentUser={currentUser}
            />
          </FormStatic>
          <FormStatic label={t('Source pool')}>
            <NavLink
              to={`/settings/key-workflow/${transaction.sourceKeyWorkflowId}`}
            >
              {transaction.sourceKeyWorkflowName}
            </NavLink>
          </FormStatic>
          <FormStatic label={t('Destination pool')}>
            <NavLink
              to={`/settings/key-workflow/${transaction.destinationKeyWorkflowId}`}
            >
              {transaction.destinationKeyWorkflowName}
            </NavLink>
          </FormStatic>
          <FormStatic label={t('Total keys')} className="num no-wrap">
            {transaction.numberOfKeys >= 0
              ? CommaNumber_NoDecimal(transaction.numberOfKeys)
              : ''}
          </FormStatic>
          <FormStatic label={t('Moved by')}>
            <>
              <UserLink id={transaction.userId} name={transaction.user} />,{' '}
              {transaction.userCompany}
            </>
          </FormStatic>
        </div>
        <div className="col">
          <FormStatic label={t('Date moved')}>
            {Date_AsString_NoHtml(transaction.creationDate)}
          </FormStatic>
          <FormStatic label={t('Status')}>
            <TransactionStatusBadge item={transaction}></TransactionStatusBadge>
          </FormStatic>
          <FormStatic label={t('Description')}>
            {transaction.description}
          </FormStatic>
          <FormStatic label={t('Message')}>{transaction.message}</FormStatic>
        </div>
      </div>
    </KwPanel>
  );
};

export default MovedTransactionDetails;
