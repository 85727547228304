import React, { useState } from 'react';
import {
  loadBulkIngestedTransactions,
  exportBulkIngestedTransactionsData,
} from '../../../actions/keyCodeActions';
import { Link, NavLink } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal, Date_AsString } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import TransactionStatusBadge from './TransactionStatusBadge';
import useUploadNotificationsGeneric from '../../../hooks/useUploadNotificationsGeneric';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import KeyProviderLink from '../../common/links/KeyProviderLink';
import UserLink from '../../common/links/UserLink';
import { useTranslation } from 'react-i18next';

function BulkIngestedBatchesList({
  location,
  reload,
  addToGroup,
  removeFromGroup,
  bulkTransactions,
  currentUser,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'uploadedDate',
    'filename',
    'keyProviderName',
    'keyCount',
    'createdByUser',
    'status',
    'message',
  ];
  const defaultVisibleColumns = allColumns;
  const [transactions, setTransactions] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'bulkingestedbatcheslist-settings',
      getTableSettingsDefaults({
        defaultSort: 'uploadedDate',
        direction: 'desc',
        defaultVisibleColumns,
      }),
      null,
      loadBulkIngestedTransactions,
      setTransactions,
      reload,
      t('Failed to load transactions'),
      location
    );
  useUploadNotificationsGeneric(
    transactions,
    setTransactions,
    'bulkTransaction',
    addToGroup,
    removeFromGroup,
    bulkTransactions,
    'id',
    (row) => row.status === 'Pending'
  );
  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'uploadedDate',
      Label: t('Uploaded date'),
      width: '10%',
      Render: (item) =>
        item.importTransactionId ? (
          <Link to={'/keys/ingested-batches/' + item.importTransactionId}>
            {Date_AsString(item.uploadedDate)}
          </Link>
        ) : (
          Date_AsString(item.uploadedDate)
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'filename',
      Label: t('Filename'),
      width: '20%',
      Render: (item) => item.filename,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'status',
      Label: t('Status'),
      width: '10%',
      className: '',
      Render: (item) => (
        <TransactionStatusBadge item={item}></TransactionStatusBadge>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyProviderName',
      Label: t('Key provider'),
      width: '10%',
      Render: (item) => (
        <KeyProviderLink id={item.keyProviderId} name={item.keyProviderName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Key pool'),
      width: '10%',
      Render: (item) =>
        hasPermission(currentUser, permissionTypes.MANAGE_WORKFLOWS) ? (
          <NavLink to={`/settings/workflow/${item.keyWorkflowId}`}>
            {item.keyWorkflowName}
          </NavLink>
        ) : (
          item.keyWorkflowName
        ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'message',
      Label: t('Message'),
      width: '10%',
      Render: (item) => item.message,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyCount',
      Label: t('Key count'),
      width: '5%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.keyCount),
    },
    {
      Key: 'createdByUser',
      Label: t('Uploaded by'),
      width: '10%',
      Render: (item) => (
        <>
          <UserLink id={item.createdByUserId} name={item.createdByUser} />
          <br />
          {item.createdByUserCompany}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return transactions ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        rows={transactions.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={transactions.totalRowCount}
        filteredRowCount={transactions.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportBulkIngestedTransactionsData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default BulkIngestedBatchesList;
