import React from 'react';
import { useTranslation } from 'react-i18next';

function TransactionStatusBadge({ item }) {
  const { t } = useTranslation();
  return item.success == null ? (
    <span className={`badge bg-info`}>{item.status || t('processing')}</span>
  ) : item.success ? (
    <span className={`badge bg-success`}>{item.status}</span>
  ) : (
    <span className={`badge bg-danger`} title={item.message}>
      {item.status}
    </span>
  );
}
export default TransactionStatusBadge;
