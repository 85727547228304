import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import RequestKeys from './slideout/RequestKeys';
import RequestsMadeByMeGroupedList from './components/RequestsMadeByMeGroupedList';
import RequestsMadeByMeList from './components/RequestsMadeByMeList';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { downloadKeys } from '../../actions/requestActions';
import { userIs } from '../auth/authUtils';
import userTypes from '../../constants/userTypes';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import TableViewSwitcher from './components/TableViewSwitcher';
import Button from '../common/Button';
import { useTranslation, Trans } from 'react-i18next';

function RequestsMadeByMe({
  currentUser,
  location,
  history,
  basket,
  setCompleteRequestSlideoutOpen,
}) {
  const { t } = useTranslation();
  const [reload, setReload] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [requestKeysSlideoutOpen, setRequestKeysSlideoutOpen] = useState(false);
  const [requestsGrouped, setRequestsGrouped] = useLocalStorage(
    'requestsGrouped',
    false
  );
  const [breadcrumb, setBreadcrumb] = useState([
    { label: t('Requests'), link: '/requests/made-by-me' },
  ]);

  const handleDownloadKeys = (e, request) => {
    e.cancelBubble = true;
    setIsProcessing(true);
    downloadKeys(request.id)
      .then(() => {
        setIsProcessing(false);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to download keys'));
        setIsProcessing(false);
      });
  };

  // reload if basket decreased
  const [itemsInBasket, setItemsInBasket] = useState();
  useEffect(() => {
    if (basket) {
      if (itemsInBasket && itemsInBasket > basket.basketCount) {
        setReload(new Date());
      }
      setItemsInBasket(basket.basketCount);
    }
  }, [basket, setItemsInBasket, itemsInBasket]);

  return (
    <>
      {basket && basket.basketCount > 0 && (
        <div
          className="alert alert-basket twinkle flex-shrink-0"
          onClick={() => setCompleteRequestSlideoutOpen(true)}
        >
          <Trans
            i18nKey="basketCount"
            components={{ bold: <b /> }}
            values={{ basketCount: basket.basketCount }}
          >
            You have <bold>{basket.basketCount}</bold> requests to complete in
            your basket.
          </Trans>
        </div>
      )}
      {basket && basket.submittingCount > 0 && (
        <div className="alert alert-basket twinkle flex-shrink-0">
          <Trans
            i18nKey="submittingCount"
            components={{ bold: <b /> }}
            values={{ submittingCount: basket.submittingCount }}
          >
            You have <bold>{basket.submittingCount}</bold> requests being
            processed.
          </Trans>
        </div>
      )}

      <ActionBar>
        {userIs(currentUser, userTypes.REQUESTER) && (
          <Button
            className="btn btn-primary"
            onClick={() => setRequestKeysSlideoutOpen(true)}
            text={t('Request keys')}
          />
        )}
      </ActionBar>
      {requestsGrouped ? (
        <RequestsMadeByMeGroupedList
          location={location}
          reload={reload}
          history={history}
          setBreadcrumb={setBreadcrumb}
          tableViewSwitcher={
            <TableViewSwitcher
              requestsGrouped={requestsGrouped}
              setRequestsGrouped={setRequestsGrouped}
            ></TableViewSwitcher>
          }
        />
      ) : (
        <RequestsMadeByMeList
          location={location}
          reload={reload}
          history={history}
          setBreadcrumb={setBreadcrumb}
          handleDownloadKeys={handleDownloadKeys}
          isProcessing={isProcessing}
          tableViewSwitcher={
            <TableViewSwitcher
              requestsGrouped={requestsGrouped}
              setRequestsGrouped={setRequestsGrouped}
            ></TableViewSwitcher>
          }
        />
      )}

      <SlidingPane
        isOpen={requestKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setRequestKeysSlideoutOpen(false)}
      >
        <RequestKeys
          done={() => {
            setRequestKeysSlideoutOpen(false);
            setCompleteRequestSlideoutOpen(true);
            setReload(new Date());
          }}
          cancel={() => setRequestKeysSlideoutOpen(false)}
        />
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    history: ownProps.history,
    currentUser: state.auth,
    basket: state.basket,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsMadeByMe);
