import React from 'react';
import { setLanguage } from '../../../../actions/userActions';
import i18next, { t } from 'i18next';
import FormSelect from '../../../common/FormSelect';

function LanguagePicker({ currentUser, user, register }) {
  const languages = [
    { value: 'en', text: 'English' },
    { value: 'fr', text: 'Français' },
    { value: 'it', text: 'Italiano' },
    { value: 'de', text: 'Deutsch' },
    { value: 'es', text: 'Español' },
    { value: 'ja', text: '日本語' },
  ];

  const handleLanguageChange = (e) => {
    if (!user) {
      console.log(currentUser);
      // API call to update the user's language
      setLanguage(e.target.value);

      // Update the language in the i18n library
      i18next.changeLanguage(e.target.value);
    }
  };

  return (
    <FormSelect
      onChange={handleLanguageChange}
      label={t('Language')}
      name="Language"
      register={register}
      defaultValue={user?.language || i18next.language}
    >
      {languages.map((language) => (
        <option key={language.value} value={language.value}>
          {language.text}
        </option>
      ))}
    </FormSelect>
  );
}

export default LanguagePicker;
