import React from 'react';
import FormGroup from './FormGroup';
import { Controller } from 'react-hook-form';
import ToggleButton from './ToggleButton';

const FormToggleBool = ({
  label,
  name,
  defaultValue,
  placeholder,
  register,
  error,
  errorMessage,
  control,
  required,
  yesText,
  noText,
  onChange,
  helpContent,
}) => {
  return (
    <FormGroup
      label={label}
      error={error}
      errorMessage={errorMessage}
      required={required}
      helpContent={helpContent}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange } }) => (
          <ToggleButton
            label={label}
            value={defaultValue}
            onChange={onChange}
            yesText={yesText}
            noText={noText}
          />
        )}
      />
    </FormGroup>
  );
};
export default FormToggleBool;
