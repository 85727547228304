import React, { useEffect, useState } from 'react';
import KwPanel from '../common/KwPanel';
import AggregateBarChart from '../common/AggregateBarChart';
import aggregateBarColours from '../../constants/aggregateBarColours';
import SlideoutLayout from '../layout/slideout/SlideoutLayout';
import useAggregateStatsData from '../../hooks/useAggregateStatsData';
import { now, nowMinusDays } from '../../util/dateUtils';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { scopes as tableScopes } from '../../constants/scopes';
import SlidingPane from 'react-sliding-pane';
import ViewOptions from './slideout/ViewOptions';
import icons from '../../constants/icons';
import DateRange from './components/DateRange';
import Loading from '../common/Loading';
import { dynamicDateRanges } from '../../util/dateRanges';
import { useTranslation } from 'react-i18next';

const AggregateStats = ({ cancel, done, mode, id }) => {
  const { t } = useTranslation();
  const defaultRange = {
    from: nowMinusDays(120),
    to: now(),
    dynamic: 'Custom Range',
  };

  const [storageRange, setStorageRange] = useLocalStorage(
    'insightsDateRange',
    'Custom Range'
  );

  const matchingDymamicRangeKey = Object.keys(dynamicDateRanges).find(
    (key) => dynamicDateRanges[key].label === storageRange
  );

  const matchingDymamicRange = dynamicDateRanges[matchingDymamicRangeKey];

  const rangeFrom = matchingDymamicRange
    ? matchingDymamicRange.calculateFrom()
    : defaultRange.from;
  const rangeTo = matchingDymamicRange
    ? matchingDymamicRange.calculateTo()
    : defaultRange.to;

  const [range, setRange] = useState({
    from: rangeFrom,
    to: rangeTo,
    dynamic: storageRange || 'Custom Range',
  });

  useEffect(() => {
    if (storageRange)
      setRange({
        from: rangeFrom,
        to: rangeTo,
        dynamic: storageRange,
      });
  }, [storageRange, rangeFrom, rangeTo]);

  const [viewOptionsSlideoutOpen, setViewOptionsSlideoutOpen] = useState(false);
  const [viewOptionsSlideoutItem, setViewOptionsSlideoutItem] = useState();

  const [
    data,
    showByCompany,
    showByGame,
    showByPlatform,
    showByKeyWorkflow,
    baseUrl,
    isLoading,
  ] = useAggregateStatsData(mode, id, range.from, range.to);

  // hold state on which viewMode we're in
  const [viewMode, setViewMode] = useLocalStorage(
    'insights-view-mode',
    'numberOfKeys'
  );
  const [title, setTitle] = useState(t('Keys'));

  // effect to set the title based on the viewMode as the viewMode is stored in local storage
  useEffect(() => {
    if (viewMode === 'numberOfRequests') {
      setTitle(t('Requests'));
    } else {
      setTitle(t('Keys'));
    }
  }, [viewMode]);

  const handleRequestsClick = () => {
    setViewMode('numberOfRequests');
  };
  const handleKeysClick = () => {
    setViewMode('numberOfKeys');
  };

  const handleClick = (viewRequestsUrl, viewItemUrl, item, typeIcon) => {
    setViewOptionsSlideoutItem({
      viewRequestsUrl,
      viewItemUrl,
      item,
      typeIcon,
    });
    setViewOptionsSlideoutOpen(true);
  };

  const handleCompanyBarClick = (item, index) => {
    const viewRequestsUrl = `${baseUrl}&${tableScopes.COMPANY}=${item.id}`;
    const viewItemUrl = `/company/${item.id}`;
    handleClick(viewRequestsUrl, viewItemUrl, item, icons.COMPANY);
  };

  const handlePlatformBarClick = (item, index) => {
    const viewRequestsUrl = `${baseUrl}&${tableScopes.PLATFORM}=${item.id}`;
    const viewItemUrl = `/settings/platform/${item.id}`;
    handleClick(viewRequestsUrl, viewItemUrl, item, icons.PLATFORM);
  };

  const handleKeyWorkflowBarClick = (item, index) => {
    const viewRequestsUrl = `${baseUrl}&${tableScopes.KEYWORKFLOW}=${item.id}`;
    const viewItemUrl = `/settings/workflow/${item.id}`;
    handleClick(viewRequestsUrl, viewItemUrl, item, icons.WORKFLOW);
  };

  const handleGameBarClick = (item, index) => {
    const viewRequestsUrl = `${baseUrl}&${tableScopes.GAME}=${item.id}`;
    const viewItemUrl = `/game/${item.id}`;
    handleClick(viewRequestsUrl, viewItemUrl, item, icons.GAME);
  };

  const handleDateRangeChange = ({ from, to, dynamic }) => {
    if ((!from || !to) && dynamic === 'Custom Range') {
      // reset to default range
      setRange(defaultRange);
      return;
    }
    setRange({ from, to, dynamic });
  };

  return (
    <SlideoutLayout
      title={t('Insights')}
      hideBar={true}
      cancel={cancel}
      done={done}
    >
      <DateRange
        className="mb-3"
        onChange={handleDateRangeChange}
        range={range}
        useDynamicRanges
        setStorageRange={setStorageRange}
      />
      <div className="aggregate-bar-key mb-3">
        <div className="toggle-view ms-4">
          <button
            className={`requests ${
              viewMode === 'numberOfRequests' ? 'active' : ''
            }`}
            onClick={handleRequestsClick}
            title={t('Requests made')}
          >
            {t('Requests')}
          </button>
          {' | '}
          <button
            className={`me-2 keys ${
              viewMode === 'numberOfKeys' ? 'active' : ''
            }`}
            onClick={handleKeysClick}
            title={t('Keys requested')}
          >
            {t('Keys')}
          </button>
        </div>
      </div>
      {isLoading && <Loading />}
      {data && !isLoading && (
        <>
          {showByCompany && (
            <KwPanel className={`mb-4`}>
              <h2>
                {title}/{t('Requesting company')}
              </h2>
              <AggregateBarChart
                seriesValuePropertyName={viewMode}
                seriesNamePropertyName="name"
                series={data.byCompany}
                colour={aggregateBarColours.COLOUR_1}
                onClick={handleCompanyBarClick}
              />
            </KwPanel>
          )}

          {showByPlatform && (
            <KwPanel className={`mb-4`}>
              <h2>
                {title}/{t('Platform')}
              </h2>
              <AggregateBarChart
                seriesValuePropertyName={viewMode}
                seriesNamePropertyName="name"
                series={data.byPlatform}
                colour={aggregateBarColours.COLOUR_3}
                onClick={handlePlatformBarClick}
              />
            </KwPanel>
          )}

          {showByKeyWorkflow && (
            <KwPanel className={`mb-4`}>
              <h2>
                {title}/{t('Workflow')}
              </h2>
              <AggregateBarChart
                seriesValuePropertyName={viewMode}
                seriesNamePropertyName="name"
                series={data.byKeyWorkflow}
                colour={aggregateBarColours.COLOUR_4}
                onClick={handleKeyWorkflowBarClick}
              />
            </KwPanel>
          )}

          {/* Keep game last as it can be long, so we'll let it scroll off the screen */}
          {showByGame && (
            <KwPanel className={`mb-4`}>
              <h2>
                {title}/{t('Game')}
              </h2>
              <AggregateBarChart
                seriesValuePropertyName={viewMode}
                seriesNamePropertyName="name"
                series={data.byGame}
                colour={aggregateBarColours.COLOUR_2}
                onClick={handleGameBarClick}
              />
            </KwPanel>
          )}
        </>
      )}

      <SlidingPane
        isOpen={viewOptionsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setViewOptionsSlideoutOpen(false)}
      >
        <ViewOptions
          viewRequestsUrl={viewOptionsSlideoutItem?.viewRequestsUrl}
          viewItemUrl={viewOptionsSlideoutItem?.viewItemUrl}
          item={viewOptionsSlideoutItem?.item}
          typeIcon={viewOptionsSlideoutItem?.typeIcon}
          done={(d) => {
            setViewOptionsSlideoutOpen(false);
          }}
          cancel={() => setViewOptionsSlideoutOpen(false)}
        />
      </SlidingPane>
    </SlideoutLayout>
  );
};

export default AggregateStats;
