import React, { useState } from 'react';
import {
  loadKeyPools,
  exportKeyPoolsData,
} from '../../../actions/metricActions';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import AutoSelectText from '../../common/AutoSelectText';
import useTableSettings from '../../../hooks/useTableSettings';
import useUploadNotifications from '../../../hooks/useUploadNotifications';
import TransactionStatusBadge from './TransactionStatusBadge';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import KebabMenu from '../../common/KebabMenu';
import Button from '../../common/Button';
import icons from '../../../constants/icons';
import GameLink from '../../common/links/GameLink';
import WorkflowLink from '../../common/links/WorkflowLink';
import skuHelp from '../../../constants/help/skuHelp';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function KeyPoolsList({
  location,
  reload,
  upload,
  addToGroup,
  removeFromGroup,
  importTransactions,
  onDisableUnused,
  currentUser,
}) {
  const language = i18next.language;
  const { t } = useTranslation();
  const allColumns = [
    'gameName',
    'packageId',
    'keyWorkflowName',
    'available',
    'used',
    'total',
  ];
  const suffixColumns = ['skuId'];
  const defaultVisibleColumns = allColumns;
  const [keypools, setKeyPools] = useState();
  useUploadNotifications({
    keypools,
    setKeyPools,
    addToGroup,
    removeFromGroup,
    importTransactions,
  });
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'keypoolslist-settings',
      getTableSettingsDefaults({
        defaultSort: 'gameName',
        defaultVisibleColumns,
      }),
      null,
      loadKeyPools,
      setKeyPools,
      reload,
      t('Failed to load key pools'),
      location
    );
  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'gameName',
      Label: t('Game'),
      width: '20%',
      Render: (item) => (
        <>
          <GameLink
            gameId={item.gameId}
            skuId={item.skuId}
            gameTitle={item.gameName}
            skuName={item.skuName}
            key={item.moveTransactionId}
            currentUser={currentUser}
          />
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'packageId',
      Label: t('Package Id'),
      className: 'autoselect',
      Render: (item) => <AutoSelectText>{item.packageId}</AutoSelectText>,
      width: '15%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Pool'),
      width: '20%',
      Render: (item) => (
        <WorkflowLink
          id={item.keyWorkflowId}
          key={item.keyWorkflowId}
          name={item.keyWorkflowName}
          colour={item.keyWorkflowColourHex}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'available',
      Label: t('Pooled'),
      helpContent: skuHelp.SKU_POOL_TABLE_POOLED[language],
      className: 'text-right num no-wrap',
      width: '15%',
      Render: (row) =>
        row.lowStock ? (
          <span className="badge bg-danger">
            {CommaNumber_NoDecimal(row.available)}
          </span>
        ) : (
          CommaNumber_NoDecimal(row.available)
        ),
    },
    {
      Key: 'used',
      Label: t('Delivered'),
      helpContent: skuHelp.SKU_POOL_TABLE_DELIVERED[language],
      className: 'text-right num no-wrap',
      width: '15%',
      Render: (row) => CommaNumber_NoDecimal(row.used),
    },
    {
      Key: 'total',
      Label: t('Total'),
      helpContent: skuHelp.SKU_POOL_TABLE_TOTAL[language],
      className: 'text-right num no-wrap',
      width: '15%',
      Render: (row) => CommaNumber_NoDecimal(row.total),
    },
    {
      Key: 'skuId',
      Label: '',
      className: 'text-right no-navigate no-wrap',
      Sort: false,
      width: '20%',
      Render: (item) =>
        item.uploadInProgress ? (
          <TransactionStatusBadge item={item}></TransactionStatusBadge>
        ) : (
          item.canUploadKeys && (
            <>
              <KebabMenu
                take={1}
                className="inline-icons"
                // horizontal={true}
                buttons={[
                  <Button
                    key={item.id + 'upload'}
                    className="btn btn-default me-2"
                    onClick={(e) => upload(e, item)}
                    icon={icons.UPLOAD_KEYS}
                    text={t('Upload')}
                  />,

                  hasPermission(currentUser, permissionTypes.MANAGE_KEYS) && (
                    <Button
                      key={item.id + 'disable'}
                      className="btn btn-default me-2"
                      isDisabled={!item.available || item.available === 0}
                      onClick={(e) => onDisableUnused(e, item)}
                      icon={icons.DISABLE_KEYS}
                      text={t('Disable unused')}
                    />
                  ),
                ]}
              ></KebabMenu>
            </>
          )
        ),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return keypools ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        suffixColumns={suffixColumnsWithConfig}
        allColumns={allColumns}
        rows={keypools.data.map((x) => {
          return { Key: `${x.skuId}-${x.keyWorkflowId}`, ...x };
        })}
        totalRowCount={keypools.totalRowCount}
        filteredRowCount={keypools.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportKeyPoolsData}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default KeyPoolsList;
