import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ToggleButton = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.value);
  const handleClick = (e, newValue) => {
    if (!props.disabled) {
      props.onChange(newValue);
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <div className="toggle-group no-wrap">
      <label
        aria-label={props.label}
        className={`no ${value === false ? 'checked' : ''}`}
        onClick={(e) => handleClick(e, false)}
      >
        {props.noText || t('No')}
      </label>
      <label
        aria-label={props.label}
        className={`yes ${value === true ? 'checked' : ''}`}
        onClick={(e) => handleClick(e, true)}
      >
        {props.yesText || t('Yes')}
      </label>
    </div>
  );
};

export default ToggleButton;
