import React from 'react';
import { RequestStatusLabel } from '../../../requests/components/RequestStatusLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import icons from '../../../../constants/icons';

function Request({ option }) {
  const data = JSON.parse(option.data);
  const { t } = useTranslation();

  return (
    <>
      <div className="icon-wrapper request">
        <FontAwesomeIcon icon={icons.REQUESTS} className="request" />
      </div>
      <div className="item-content">
        <span>
          {option.title} - {data.GameTitle} {data.SKUName}
        </span>
        <br />
        {data.NumberOfKeys} {data.NumberOfKeys > 1 ? t('Keys') : t('Key')}{' '}
        {data.RequestStatus && (
          <RequestStatusLabel
            requestStatus={{
              cssClasses: data.RequestStatus.CssClasses,
              id: data.RequestStatus.Id,
              isAuthorised: data.RequestStatus.IsAuthorised,
              isDeclined: data.RequestStatus.IsDeclined,
              isKeysOrdered: data.RequestStatus.IsKeysOrdered,
              isPending: data.RequestStatus.IsPending,
              isWithdrawn: data.RequestStatus.IsWithdrawn,
              name: data.RequestStatus.Name,
            }}
            isWaitingForKeys={data.IsWaitingForKeys}
            isUpdateInProgress={data.IsUpdateInProgress}
            isDownloaded={data.Downloaded}
          />
        )}
        {console.log(data.RequestStatus)}
      </div>
    </>
  );
}
export default Request;
